import HTTPService from "@/services/HTTPService";
import AuthService from "@/services/AuthService";

export default (function() {

    async function constructAuthHeader() {
        return {'Authorization': 'Bearer '+ await AuthService.getAuthenticationToken()}
    }

    return {
        async getMovementTypes() {
            const response = await HTTPService.get('event-types', undefined, await constructAuthHeader());
            return response.json();
        },
        async getMovements(filter) {
            const response = await HTTPService.get('events', {filter: JSON.stringify(filter)}, await constructAuthHeader());
            return response.json();
        },
        async getTodaysMovementTotals() {
            const response = await HTTPService.get('events/totals/today', undefined, await constructAuthHeader());
            return response.json();
        },
        async getMovementThresholds() {
            const response = await HTTPService.get('thresholds', undefined, await constructAuthHeader());
            return response.json();
        },
        async addMovement(movement) {
            const response = await HTTPService.post('events', {event: movement}, await constructAuthHeader());
            const result = await response.json();
            if(response.status >= 400) {
                throw new Error(result)
            }
            return result;
        },
        async editMovement(id, data) {
            const response = await HTTPService.patch('events/'+id, {event: data}, await constructAuthHeader());
            let result = "";
            try {
                result = await response.json();
            } catch(e) {}

            if(response.status >= 400) {
                throw new Error(result)
            }

            return result;
        },
        async getDataEntries(filter) {
            const response = await HTTPService.get('data', {filter: JSON.stringify(filter)}, await constructAuthHeader());
            return response.json();
        },
        async getResidents(filter) {
            const response = await HTTPService.get('residents', {filter: JSON.stringify(filter)}, await constructAuthHeader());
            return response.json();
        },
        async addResident(transponder, registration) {
            const response = await HTTPService.post('residents', {resident: {transponder, registration}}, await constructAuthHeader());
            return response.json();
        },
        async deleteResident(residentId) {
            await HTTPService.remove('residents/'+residentId, await constructAuthHeader());
        },
        async getAircraftTypes() {
            const response = await HTTPService.get('aircraft-types', {}, await constructAuthHeader());
            return response.json();
        },
        async getAircraft(filter) {
            const response = await HTTPService.get('aircraft', {filter: JSON.stringify(filter)}, await constructAuthHeader());
            return response.json();
        },
        async addAircraft(aircraft_type, registration, resident_id) {
            const response = await HTTPService.post('aircraft', {aircraft: {aircraft_type, registration, resident_id}}, await constructAuthHeader());
            return response.json();
        },
        async deleteAircraft(aircraftId) {
            await HTTPService.remove('aircraft/'+aircraftId, await constructAuthHeader());
        },
        async getSpecialDateTypes() {
            const response = await HTTPService.get('special-date-types', {}, await constructAuthHeader());
            return response.json();
        },
        async getSpecialDates(filter) {
            const response = await HTTPService.get('special-dates', {filter: JSON.stringify(filter)}, await constructAuthHeader());
            return response.json();
        },
        async addSpecialDate(label, date, special_date_type) {
            const response = await HTTPService.post('special-dates', {special_date: {label, date, special_date_type}}, await constructAuthHeader());
            return response.json();
        },
        async deleteSpecialDate(specialDateId) {
            await HTTPService.remove('special-dates/'+specialDateId, await constructAuthHeader());
        },
        async getRoles() {
            const response = await HTTPService.get('roles', {}, await constructAuthHeader());
            return response.json();
        },
        async getRolesForUser(userId) {
            const response = await HTTPService.get('users/' + userId + '/roles', {}, await constructAuthHeader());
            return response.json();
        },
        async getUsers(filter) {
            const response = await HTTPService.get('users', {filter: JSON.stringify(filter)}, await constructAuthHeader());
            return response.json();
        },
        async addUser(username, password, roles) {
            const response = await HTTPService.post('users', {user: {username, password}, roles}, await constructAuthHeader());
            const result = await response.json();
            if(response.status >= 400) {
                throw new Error(result)
            }
            return result;
        },
        async editUser(userId, fields, roles) {
            const data = {user: fields};
            if(roles && roles.length) {
                data.roles = roles;
            }
            const response = await HTTPService.patch('users/'+userId, data, await constructAuthHeader());
            let result = "";
            try {
                result = await response.json();
            } catch(e) {}

            if(response.status >= 400) {
                throw new Error(result)
            }

            return result;

        },
        async deleteUser(userId) {
            await HTTPService.remove('users/'+userId, await constructAuthHeader());
        },
        async getAuditLogs(filter) {
            const response = await HTTPService.get('audit', {filter: JSON.stringify(filter)}, await constructAuthHeader());
            return response.json();
        }
    }
})();
